/* eslint-disable default-case */
// import { LogLevel } from "@azure/msal-browser";

/**
 * Configuration object to be passed to MSAL instance on creation.
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 */
export const msalConfig = {
  auth: {
    clientId: "89a7f6fa-86d8-419c-bdeb-7f76eca10d8e",
    authority: "https://login.microsoftonline.com/stritas.qld.edu.au",
    redirectUri: "https://aara.stritas.qld.edu.au",
    // redirectUri: "http://localhost:3000",
  },
  cache: {
    cacheLocation: "sessionStorage",
    // cacheLocation: "localStorage",
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        // switch (level) {∏
        //   case LogLevel.Error:
        //     console.error(message);
        //     return;
        //   case LogLevel.Info:
        //     console.info(message);
        //     return;
        //   case LogLevel.Verbose:
        //     console.debug(message);
        //     return;
        //   case LogLevel.Warning:
        //     console.warn(message);
        //     return;
        // }
      },
    },
  },
};

export const loginRequest = {
  scopes: [
    "User.Read",
    "User.Read.All",
    "Group.Read.All",
    "GroupMember.Read.All",
  ],
};

export const storageRequest = {
  scopes: ["https://storage.azure.com/user_impersonation"],
};

export const dbRequest = {
  scopes: ["https://database.windows.net/user_impersonation"],
};

export const protectedRequest = {
  scopes: ["api://89a7f6fa-86d8-419c-bdeb-7f76eca10d8e/access_as_user"],
};

//-------SERVER URLS

export const serverUrl = "https://srcnccd.azurewebsites.net";

// export const serverUrl = "http://localhost:4000";

export const studentEndpoint =
  "https://srcnccdstudentfiles.blob.core.windows.net";

export const storageEndpoint = "https://aarahub.blob.core.windows.net";

export const graphEndpoint = "https://graph.microsoft.com/v1.0/me";

export const studentFileAccount = "srcnccdstudentfiles";

export const mainStorageAccount = "aarahub";

export const reportId = ""
